import { $ } from './dom-utils'

const toggleSwitch = $('.dark-mode-switch input[type="checkbox"]')
const doc = document.documentElement

function switchTheme(e) {
  if (e.target.checked) {
    doc.setAttribute('theme', 'dark')
    localStorage.setItem('theme', 'dark')
  } else {
    doc.setAttribute('theme', 'light')
    localStorage.setItem('theme', 'light')
  }
}

export function isDarkMode(){
  return localStorage.getItem('theme') === 'dark'
}

toggleSwitch.addEventListener('change', switchTheme, false)

const themeActuel = localStorage.getItem('theme')
  ? localStorage.getItem('theme')
  : null

if (themeActuel) {
  doc.setAttribute('theme', themeActuel)

  if (themeActuel === 'dark') {
    toggleSwitch.checked = true
  }
}
