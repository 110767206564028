import 'leaflet/dist/leaflet.css'
import L, { featureGroup } from 'leaflet'
import 'esri-leaflet'
import { geocode } from 'esri-leaflet-geocoder'

import { $, createElement } from './dom-utils'
import { isDarkMode } from './dark-mode'
import { featureLayer } from 'esri-leaflet'

// Bordures de la France
const southWest = L.latLng(42.144314, -5.428104)
const northEast = L.latLng(51.369423, 9.029903)
const franceBounds = L.latLngBounds(southWest, northEast) // métropole

let mapSport = null
let featuresGroupSport = null;

let mapAnimaux = null
let featuresGroupAnimaux = null;

let latLngAddress = null

function createMaps() {
    mapSport = L.map('mapSport', { scrollWheelZoom: 'center' }).setView(
        [48.8533264, 2.3487669],
        13,
    )

    mapAnimaux = L.map('mapAnimaux', { scrollWheelZoom: 'center' }).setView(
        [48.8533264, 2.3487669],
        13,
    )
}

// ajoute un div 'map' cache
function addHtmlElement() {
    const checkboxWithMapSport = $('.form-checkbox [for="checkbox-sport"]')
    const checkboxWithMapAnimaux = $('.form-checkbox [for="checkbox-animaux"]')

    const mapHtmlElementAttrSport = {
        id: 'mapSport',
    }
    const mapHtmlElementAttrAnimaux = {
        id: 'mapAnimaux',
    }

    const mapHtmlElementSport = createElement('div', mapHtmlElementAttrSport)
    mapHtmlElementSport.style.display = 'none'
    mapHtmlElementSport.classList.add("map");
    checkboxWithMapSport.parentNode.insertBefore(
        mapHtmlElementSport,
        checkboxWithMapSport.nextSibling,
    )

    const mapHtmlElementAnimaux = createElement('div', mapHtmlElementAttrAnimaux)
    mapHtmlElementAnimaux.style.display = 'none'
    mapHtmlElementAnimaux.classList.add("map");
    checkboxWithMapAnimaux.parentNode.insertBefore(
        mapHtmlElementAnimaux,
        checkboxWithMapAnimaux.nextSibling,
    )

    createMaps()
    featuresGroupSport = L.featureGroup().addTo(mapSport);
    featuresGroupAnimaux = L.featureGroup().addTo(mapAnimaux);
    setTimeoutMap(mapSport)
    setTimeoutMap(mapAnimaux)
}

function setupLeaflet(map) {
    // init map sur paris
    if (isDarkMode()) {
        L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
            attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
            accessToken: 'pk.eyJ1Ijoid296bWFuIiwiYSI6ImNraDdzYzJ4ZTBkbDEyeG5xbWFnMWR5a3EifQ.4SwKjG_75gsTnQBO-MMoMg',
            id: 'mapbox/dark-v10',
        }).addTo(map)
    } else {
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
        }).addTo(map)
    }

    var featuresGroup = null;

    if (map == mapSport) {
        featuresGroup = featuresGroupSport;
    } else {
        featuresGroup = featuresGroupAnimaux;
    }


    featuresGroup.clearLayers();

    // creer cercle
    const circle = L.circle(map.getCenter(), {
        radius: map == mapSport ? 10000 : 1000,
        color: 'red',
        weight: 1,
        fillOpacity: isDarkMode() ? 0.04 : 0.15,
    }).addTo(featuresGroup)

    // on garde le cercle au meme endroit quand on deplace la map
    map.on('move', (e) => {
        if (latLngAddress !== null) {
            circle.setLatLng(latLngAddress)
        }
        map._renderer._update()
    })

    goToFormAddress(map)
    setTimeoutMap(map)
}

// Centrer la map sur l'adresse
function goToFormAddress(map) {
    const address = $('#field-address').value
    const city = $('#field-city').value

    if (address === '' && city === '') return

    const geoCode = geocode()

    if (!!address && address.length) geoCode.address(address)
    if (!!city && city.length) geoCode.city(city)

    geoCode
        .within(franceBounds)
        .country('France')
        .run((err, response) => {
            if (err) {
                console.error(err)
                return
            }

            if (response.results.length === 0) return

            const latlng = response.results[0].latlng

            latLngAddress = latlng
            map.setView(latlng, map == mapSport ? 11 : 13)
        })
}

function setTimeoutMap(map) {
    setTimeout(function() {
        map.invalidateSize()
    }, 4000)
}

function closeLeaflet(div, map) {
    div.style.display = 'none'
    setTimeoutMap(map)
}

function showLeaflet(div, map) {
    div.style.display = 'block'
    setTimeoutMap(map)
}

function onCheckboxSwitched() {
    var map = null;
    var div = null;
    if (this.id == "checkbox-sport") {
        map = mapSport;
        div = $("#mapSport")
    } else {
        map = mapAnimaux
        div = $("#mapAnimaux")
    }

    // on active et desactive la map en fonction de la checkbox
    if (this.checked) {
        showLeaflet(div, map)
        setupLeaflet(map)
    } else {
        closeLeaflet(div, map)
    }
}

function changeDarkLayer() {
    setupLeaflet(mapSport);
    setupLeaflet(mapAnimaux);
}

// on ajoute la map
export function appendMap() {
    const darkSwitch = $('.dark-mode-switch input[type="checkbox"]')

    addHtmlElement()
    $('#checkbox-sport').addEventListener('change', onCheckboxSwitched)
    $('#checkbox-animaux').addEventListener('change', onCheckboxSwitched)
    darkSwitch.addEventListener('change', changeDarkLayer)

    const fields = [$('#field-city'), $('#field-address')]
    fields.forEach((input) => {
        input.addEventListener('input', function() {
            if (map != null) {
                goToFormAddress(map)
            }
        })
    })
}