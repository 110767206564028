import removeAccents from 'remove-accents'

import { $, $$, downloadBlob } from './dom-utils'
import { addSlash, getFormattedDate, getFormattedTime } from './util'
import pdfBase from '../certificate.pdf'
import { generatePdf } from './pdf-util'
import SecureLS from 'secure-ls'
import formData from '../form-data.json'


const secureLS = new SecureLS({ encodingType: 'aes' })
const reasonsData = formData.flat(1).find((field) => field.key === 'reason')
console.log(reasonsData)
const conditions = {
    '#field-firstname': {
        length: 1,
    },
    '#field-lastname': {
        length: 1,
    },
    '#field-birthday': {
        pattern: /^([0][1-9]|[1-2][0-9]|30|31)\/([0][1-9]|10|11|12)\/(19[0-9][0-9]|20[0-1][0-9]|2020)/g,
    },
    '#field-address': {
        length: 1,
    },
    '#field-city': {
        length: 1,
    },
    '#field-zipcode': {
        pattern: /\d{5}/g,
    },
    '#field-datesortie': {
        pattern: /\d{4}-\d{2}-\d{2}/g,
    },
    '#field-heuresortie': {
        pattern: /\d{2}:\d{2}/g,
    },
}

function validateAriaFields() {
    return Object.keys(conditions)
        .map((field) => {
            const fieldData = conditions[field]
            const pattern = fieldData.pattern
            const length = fieldData.length
            const isInvalidPattern = pattern && !$(field).value.match(pattern)
            const isInvalidLength = length && !$(field).value.length

            const isInvalid = !!(isInvalidPattern || isInvalidLength)

            $(field).setAttribute('aria-invalid', isInvalid)
            if (isInvalid) {
                $(field).focus()
            }
            return isInvalid
        })
        .includes(true)
}

export function setReleaseDate(releaseDateInput) {
    const loadedDate = new Date(Date.now() + getDateDecalage())
    releaseDateInput.value = getFormattedDate(loadedDate)
}

export function setReleaseTime(releaseTimeInput) {
    const loadedDate = new Date(Date.now() + getDateDecalage())
    releaseTimeInput.value = getFormattedTime(loadedDate)
}

export function getDateDecalage() {
    const decalage = 2 // en min
    return decalage * 60 //_000
}

function updateSecureLS(formInputs, forceSaved = false) {
    if (isSavedMode() || forceSaved) {
        secureLS.set('profile', getProfile(formInputs))
    }
}

function clearSecureLS() {
    secureLS.clear()
}

function clearForm() {
    const evt = document.createEvent('HTMLEvents')
    evt.initEvent('change', false, true)
    $("#checkbox-animaux").dispatchEvent(evt)

    const formProfile = $('#form-profile')
    formProfile.reset()
}

function showSnackbar(snackbarToShow, showDuration = 6000) {
    snackbarToShow.classList.remove('d-none')
    setTimeout(() => snackbarToShow.classList.add('show'), 100)

    setTimeout(function() {
        snackbarToShow.classList.remove('show')
        setTimeout(() => snackbarToShow.classList.add('d-none'), 500)
    }, showDuration)
}

export function toAscii(string) {
    if (typeof string !== 'string') {
        throw new Error('Need string')
    }
    const accentsRemoved = removeAccents(string)
    const asciiString = accentsRemoved.replace(/[^\x00-\x7F]/g, '') // eslint-disable-line no-control-regex
    return asciiString
}

export function getProfile(formInputs) {
    const fields = {}
    for (const field of formInputs) {
        let value = field.value
        if (field.id === 'field-datesortie') {
            const dateSortie = field.value.split('-')
            value = `${dateSortie[2]}/${dateSortie[1]}/${dateSortie[0]}`
        }
        if (typeof value === 'string') {
            value = toAscii(value)
        }
        fields[field.id.substring('field-'.length)] = value
    }
    return fields
}

export function getReasons(reasonInputs) {
    const reasons = reasonInputs
        .filter((input) => input.checked)
        .map((input) => input.value)
        .join(', ')
    return reasons
}

export function prepareInputs(
    formInputs,
    reasonInputs,
    reasonFieldset,
    reasonAlert,
    dlSnackbar,
    clearDataSnackbar,
) {
    const lsProfile = secureLS.get('profile')
    const releaseDateInput = $('#field-datesortie')
    const releaseTimeInput = $('#field-heuresortie')

    if (isSavedMode()) {
        setSavedMode()
    } else {
        setUnSavedMode()
    }

    formInputs.forEach((input) => {
        if (
            input.name &&
            lsProfile &&
            input.name !== 'datesortie' &&
            input.name !== 'heuresortie' &&
            input.name !== 'field-reason'
        ) {
            input.value = lsProfile[input.name]
        }
        const exempleElt = input.parentNode.parentNode.querySelector('.exemple')
        if (input.placeholder && exempleElt) {
            input.addEventListener('input', (event) => {
                if (input.value) {
                    //updateSecureLS(formInputs)
                    exempleElt.innerHTML = 'ex.&nbsp;: ' + input.placeholder
                } else {
                    exempleElt.innerHTML = ''
                }
            })
        }
    })

    setReleaseDate(releaseDateInput)
    setReleaseTime(releaseTimeInput)

    $('#field-birthday').addEventListener('keyup', function(event) {
        event.preventDefault()
        const input = event.target
        const key = event.keyCode || event.charCode
        if (key !== 8 && key !== 46) {
            input.value = addSlash(input.value)
        }
    })

    reasonInputs.forEach((radioInput) => {
        radioInput.addEventListener('change', function(event) {
            const isInError = reasonInputs.every((input) => !input.checked)
            reasonFieldset.classList.toggle('fieldset-error', isInError)
            reasonAlert.classList.toggle('hidden', !isInError)
            radioInput.parentElement.classList.remove('reason-couvrefeu-error')
        })

        const id = radioInput.id
        const isChecked = localStorage.getItem(id)
        if (isChecked === 'true') {
            radioInput.checked = true
            const evt = document.createEvent('HTMLEvents')
            evt.initEvent('change', false, true)
            radioInput.dispatchEvent(evt)
        }
    })

    $('#edit-btn').addEventListener('click', (event) => {
        setEditMode()
    })

    $('#clearLink').addEventListener('click', (event) => {
        localStorage.clear()
        setUnSavedMode()
        $('#form-profile').reset()
        reasonInputs.forEach((radioInput) => {
                radioInput.removeAttribute('checked')
            })
            //
        clearSecureLS()
        clearForm()
        showSnackbar(clearDataSnackbar, 3000)
            //
        setReleaseDate(releaseDateInput)
        setReleaseTime(releaseTimeInput)
    })

    $('#generate-btn').addEventListener('click', async(event) => {
        event.preventDefault()

        $('#error-alert').classList.add('d-none')
        $('#error-couvrefeu-alert').classList.add('d-none')

        let quit = false;

        reasonInputs.forEach((radioInput) => {
            const id = radioInput.id
            const isChecked = radioInput.checked
            const code = radioInput.value
            if (!isAllowed(code) && isChecked) {
                //console.log($("#field-heuresortie").value.split(":")[0] >= 19)
                console.log("impossible couvre feu " + id)
                radioInput.parentElement.classList.add('reason-couvrefeu-error')
                $('#error-couvrefeu-alert').classList.remove('d-none')
                quit = true;
            }
        })

        if (quit) {
            return
        }


        const reasons = getReasons(reasonInputs)
        if (!reasons) {
            reasonFieldset.classList.add('fieldset-error')
            reasonAlert.classList.remove('hidden')
            reasonFieldset.scrollIntoView && reasonFieldset.scrollIntoView()
            $('#error-alert').classList.remove('d-none')
            return
        }

        const invalid = validateAriaFields()
        if (invalid) {
            $('#error-alert').classList.remove('d-none')
            return
        }

        if (isSavedMode() && !isEditMode()) {
            setReleaseDate(releaseDateInput)
            setReleaseTime(releaseTimeInput)
        }

        reasonInputs.forEach((radioInput) => {
            const id = radioInput.id
            const isChecked = radioInput.checked
            if (isChecked === true) {
                localStorage.setItem(id, isChecked)
            } else {
                localStorage.removeItem(id)
            }
        })

        updateSecureLS(formInputs, true)

        setUnEditMode()
        setSavedMode()

        console.log(getProfile(formInputs), reasons)

        const pdfBlob = await generatePdf(getProfile(formInputs), reasons, pdfBase, isCouvreFeu() ? 1 : 0)

        const creationInstant = new Date()
        const creationDate = creationInstant.toLocaleDateString('fr-CA')
        const creationHour = creationInstant
            .toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })
            .replace(':', '-')

        downloadBlob(pdfBlob, `attestation-${creationDate}_${creationHour}.pdf`)
        showSnackbar(dlSnackbar, 6000)
    })
}

export function prepareForm() {
    const formInputs = $$('#form-profile input')
    const dlSnackbar = $('#snackbar-dl')
    const clearDataSnackbar = $('#snackbar-cleardata')
    const reasonInputs = [...$$("input[name='field-reason']")]
    const reasonFieldset = $('#reason-fieldset')
    const reasonAlert = reasonFieldset.querySelector('.msg-alert')

    prepareInputs(
        formInputs,
        reasonInputs,
        reasonFieldset,
        reasonAlert,
        dlSnackbar,
        clearDataSnackbar,
    )
}

/// //////////////////

function setSavedMode() {
    const form = $('#form-profile')
    const reasonInputs = [...$$("input[name='field-reason']")]
    const footnotes = $('#footnotes')
    const infoAttest = $('#infoAttest')
    const editButton = $('#edit-btn')
    const lsProfile = secureLS.get('profile')

    form.style.display = 'none'
    footnotes.style.display = 'none'
    let reasons = ''
    reasonInputs.forEach((reason) => {
        const id = reason.id
        const isChecked = localStorage.getItem(id)
        if (isChecked === 'true') {
            reasons = reasons + id.substr(9) + ' '
        }
    })

    infoAttest.innerHTML = `Attestation pour : ${lsProfile.firstname} ${lsProfile.lastname}
Motif : ${reasons}`

    infoAttest.removeAttribute('hidden')
    editButton.style.visibility = 'visible'

    localStorage.setItem('savedMode', 'true')
}

function setUnSavedMode() {
    setEditMode()
    localStorage.setItem('savedMode', 'false')
}

function isSavedMode() {
    return localStorage.getItem('savedMode') === 'true'
}

function setEditMode() {
    const form = $('#form-profile')
    const footnotes = $('#footnotes')
    const infoAttest = $('#infoAttest')
    const editButton = $('#edit-btn')
    const errorAlert = $('#error-alert')

    localStorage.setItem('editMode', 'true')
    infoAttest.setAttribute('hidden', '')
    editButton.style.visibility = 'hidden'
    form.style.display = 'block'
    footnotes.style.display = 'block'
    errorAlert.classList.add('d-none')
}

function setUnEditMode() {
    localStorage.setItem('editMode', 'false')
}

function isEditMode() {
    return localStorage.getItem('editMode') === 'true'
}

function isCouvreFeu() {
    return ($("#field-heuresortie").value.split(":")[0] >= 19) || ($("#field-heuresortie").value.split(":")[0] < 6)
}

function isAllowed(code) {
    for (const item of reasonsData.items) {
        if (item.code == code) {
            console.log("type " + item.type)
            console.log("couvrefeu " + isCouvreFeu())
            console.log(isCouvreFeu() && !(item.type == "confinement"))
            console.log(!isCouvreFeu() && !(item.type == "couvrefeu"))
            console.log((isCouvreFeu() && !(item.type == "confinement")) || (!isCouvreFeu() && !(item.type == "couvrefeu")))
            return (isCouvreFeu() && !(item.type == "confinement")) || (!isCouvreFeu() && !(item.type == "couvrefeu"))
        }
    }
}