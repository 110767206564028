import { generateQR } from './util'
import { drawRectangle, PDFDocument, rgb, StandardFonts } from 'pdf-lib'

const ys = [{
    travail: 540,
    sante: 487,
    famille: 435,
    convocation_demarches: 364,
    demenagement: 294,
    achats_culte_culturel: 242,
    sport: 177,
}, {
    travail: 540,
    sante: 487,
    famille: 435,
    convocation_demarches: 365,
    animaux: 110,
}]


export async function generatePdf(profile, reasons, pdfBase, type) {
    const creationInstant = new Date()
    const creationDate = creationInstant.toLocaleDateString('fr-FR')
    const creationHour = creationInstant
        .toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })
        .replace(':', 'h')

    const {
        lastname,
        firstname,
        birthday,
        address,
        zipcode,
        city,
        datesortie,
        heuresortie,
    } = profile

    const data = [
        `Cree le: ${creationDate} a ${creationHour}`,
        `Nom: ${lastname}`,
        `Prenom: ${firstname}`,
        `Naissance: ${birthday}`,
        `Adresse: ${address} ${zipcode} ${city}`,
        `Sortie: ${datesortie} a ${heuresortie}`,
        `Motifs: ${reasons}`,
        '', // Pour ajouter un ; aussi au dernier élément
    ].join(';\n')

    const existingPdfBytes = await fetch(pdfBase).then((res) => res.arrayBuffer())

    const pdfDoc = await PDFDocument.load(existingPdfBytes)

    // set pdf metadata
    pdfDoc.setTitle('COVID-19 - Déclaration de déplacement')
    pdfDoc.setSubject('Attestation de déplacement dérogatoire')
    pdfDoc.setKeywords([
        'covid19',
        'covid-19',
        'attestation',
        'déclaration',
        'déplacement',
        'officielle',
        'gouvernement',
    ])
    pdfDoc.setProducer('DNUM/SDIT')
    pdfDoc.setCreator('')
    pdfDoc.setAuthor("Ministère de l'intérieur")

    const page1 = pdfDoc.getPages()[0]

    const font = await pdfDoc.embedFont(StandardFonts.Helvetica)
    const drawText = (text, x, y, size = 11) => {
        page1.drawText(text, { x, y, size, font })
    }

    const drawTextPageX = (page, text, x, y, size = 11) => {
        page.drawText(text, { x, y, size, font })
    }

    drawText(`${lastname} ${firstname}`, 144, 670)
    drawText(birthday, 119, 660)
    drawText(`${address} ${zipcode} ${city}`, 144, 650)

    var page = page1
    const page2 = pdfDoc.getPages()[1]

    reasons
        .split(', ')
        .forEach(reason => {
            //if(reason == "courses"){
            //  page = page2
            //}
            drawTextPageX(page, 'x', type == 0 ? 508 : 552, ys[type][reason], 12)
        })

    let locationSize = getIdealFontSize(font, profile.city, 83, 7, 11)

    if (!locationSize) {
        alert(
            'Le nom de la ville risque de ne pas être affiché correctement en raison de sa longueur. ' +
            'Essayez d\'utiliser des abréviations ("Saint" en "St." par exemple) quand cela est possible.',
        )
        locationSize = 7
    }

    //drawTextPageX(page, `Fait à ${profile.city}`, 72, 99, locationSize)
    page.drawRectangle({
        x: 360,
        y: 65,
        width: 120,
        height: 12,
        color: rgb(1, 1, 1),
    })
    drawTextPageX(page, `Le : ${profile.datesortie}`, 360, 66, 11)
    drawTextPageX(page, `à ${profile.heuresortie}`, 450, 66, 11)

    // const shortCreationDate = `${creationDate.split('/')[0]}/${
    //   creationDate.split('/')[1]
    // }`
    // drawText(shortCreationDate, 314, 189, locationSize)

    // // Date création
    // drawText('Date de création:', 479, 130, 6)
    // drawText(`${creationDate} à ${creationHour}`, 470, 124, 6)

    const qrTitle1 = 'QR-code contenant les informations '
    const qrTitle2 = 'de votre attestation numérique'

    const generatedQR = await generateQR(data)

    const qrImage = await pdfDoc.embedPng(generatedQR)

    page1.drawText(qrTitle1 + '\n' + qrTitle2, { x: 440, y: 630, size: 6, font, lineHeight: 10, color: rgb(1, 1, 1) })

    page1.drawImage(qrImage, {
        x: page1.getWidth() - 107,
        y: 660 - 25,
        width: 82,
        height: 82,
    })

    pdfDoc.addPage()
    const lastPage = pdfDoc.getPages()[1]
    lastPage.drawText(qrTitle1 + qrTitle2, { x: 50, y: lastPage.getHeight() - 40, size: 11, font, color: rgb(1, 1, 1) })
    lastPage.drawImage(qrImage, {
        x: 50,
        y: lastPage.getHeight() - 350,
        width: 300,
        height: 300,
    })

    const pdfBytes = await pdfDoc.save()

    return new Blob([pdfBytes], { type: 'application/pdf' })
}

function getIdealFontSize(font, text, maxWidth, minSize, defaultSize) {
    let currentSize = defaultSize
    let textWidth = font.widthOfTextAtSize(text, defaultSize)

    while (textWidth > maxWidth && currentSize > minSize) {
        textWidth = font.widthOfTextAtSize(text, --currentSize)
    }

    return textWidth > maxWidth ? null : currentSize
}